<template>
    <!-- 个人中心-我的资源-摄影师-资料组件 -->
    <div class="center_lensman_info_data_box">
        <el-form 
            :model="form"
            :rules="rules"
            label-width="120px"
            label-position="left">
            <!-- 擅长 -->
            <el-form-item label="个性签名">
                <el-input 
					maxlength="30"
                    v-model="form.skilled"
                    class="form_input"
                    placeholder="请输入个性签名">
                </el-input>
            </el-form-item>
            <!-- 作品封面照 -->
            <el-form-item label="作品封面照">
                <div style="color: #E6A23C">作品封面照建议最多三张，建议图片大小2M以内</div>
                <slot name="tips"></slot>
                <div class="center-lensman-info-pic-box"
                    v-for="(item, index) in form.student_pic"
                    :key="index"
                    @mouseenter="handleEnter(index)"
                    @mouseleave="handleLeave">
                    <!-- 删除 -->
                    <div :class="[
                            'center-lensman-info-pic-del',
                            {
                                'del-hover' : hover_index == index
                            }
                        ]">
                        <el-button
                            type="text"
                            @click="handleDelPic(index)"
                            class="center-lensman-info-pic-del-btn">
                            <i class="el-icon-delete"></i>
                        </el-button>
                    </div>
                    <!-- 图片 -->
                    <el-image
                        :src="item"
                        fit="scale-down"
                        lazy
                        class="center-lensman-info-pic">
                        <!-- 加载中样式 -->
                        <template v-slot:placeholder>
                            <div class="image-slot">
                                <i class="el-icon-loading"></i>
                            </div>
                        </template>
                        <!-- 加载失败样式 -->
                        <template v-slot:error>
                            <div class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </template>
                    </el-image>
                </div>
                <el-upload
                    action=""
                    class="center-lensman-info-upload"
                    list-type="picture-card"
                    :show-file-list="false"
                    :multiple="false"
                    :auto-upload="false"
                    :on-change="handleUpload">
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <el-form-item
                label="所在地区"
                prop="service">
                <el-cascader
                    :options="options"
                    :props="{
                        value: 'label'
                    }"
                    v-model="form.service"
                    size="small"
                    class="center-member-info-cascader">
                </el-cascader>
            </el-form-item>
            <el-form-item
                label="服务范围"
                prop="address">
                <el-input
                    v-model="form.address"
                    class="center-member-info-map-search"
                    @keyup.enter.native="handleSearch">
                    <el-button 
                        slot="append" 
                        icon="el-icon-search"
                        @click="handleSearch">
                    </el-button>
                </el-input>
                <!-- <el-link 
                    type="danger" 
                    class="center-member-info-map-danger"
                    :underline="false"
                    v-if="position.lat == '' && position.lng == ''">
                    您还未选择服务范围！
                </el-link> -->
                <div class="center-member-info-map">
                    <b-map
                        :map-height="300"
                        :map-position="position"
                        :map-change="setInfoChange"
                        ref="mapSearch">
                    </b-map>
                </div>
            </el-form-item>
            <el-form-item
                label="简介"
                prop="brief">
                <el-input
					maxlength="100"
                    v-model="form.brief"
                    type="textarea">
                </el-input>
            </el-form-item>
        </el-form>
        <!-- 提交按钮 -->
        <div class="center_lensman_info_data_btn_box">
            <el-button 
                type="warning"
                @click="handleSave(form)">
                <span>保存</span>
            </el-button>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { handleBase64 } from "@/tool/publicMethod.js";
import { regionData } from "element-china-area-data";


export default {
    data() {
        return {
            options: regionData,
            position: {
                lat: "", // 纬度
                lng: "", // 经度
            },
            // 表单数据
            form: {
                avatar: "",
                skilled: "",
                student_pic: [],
                service: [],
                address: "",
                brief: ""
            },
            rules: {
                service:[
                    { required: false, message: '请选择所在地区', trigger: 'blur' }
                ],
                address:[
                    { required: false, message: '请选择服务范围', trigger: 'blur' }
                ],
                brief: [
                    { required: false, message: '请填写简介', trigger: 'blur' }
                ]
            },
            hover_index: -1,
            dialogImageUrl: '',
        }
    },
    props: [ "handleAvatar", "handleInfo" ],
    computed: mapState({
        resource: state => state.user.resource,
    }),
    components: {
        bMap: () => import("@/components/bMap")
    },
    methods: {
        ...mapMutations([
            "changeDialogVisible",
            "setInfoChange"
        ]),
        ...mapActions([
            "handleUploadImage",
        ]),
        setForm(val){
            if(!val.userID) return;
            this.form = {
                avatar: val.head,
                skilled: val.goodat,
                student_pic: val.certphoto,
                service: val.serviceArea || [],
                address: val.address || this.form.address,
                brief: val.briefInfo || ""
            }
            if(val.position && JSON.stringify(val.position) !== "{}"){
                this.position = {
                    lat: val.position.lat,
                    lng: val.position.long,
                } 
            }
        },
        handleRequestAvatar(val){ // 头像修改
            const isLt2M = val.file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
                return ;
            }
            handleBase64(val.file)
            .then(this.handleAvatar)
            .then(res => {
                if(res.type){
                    this.$message({
                        showClose: true,
                        message: "头像修改成功",
                        type: "success"
                    })
                    this.form.avatar = res.msg;
                }else{
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: "error"
                    })
                }
            })
        },
        handleEnter(i){ // 鼠标悬停封面照
            this.hover_index = i;
        },
        handleLeave(){ // 鼠标离开封面照
            this.hover_index = -1;
        },
        handleDelPic(i){ // 删除图片
            this.form.student_pic.splice(i,1);
        },
        handleUpload(file, fileList){ // 上传图片
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
                return ;
            }
            handleBase64(file.raw)
            .then( this.handleUploadImage )
            .then( res => {
                if(res.type) this.form.student_pic.push(res.result);
            })
        },
        handleSearch(){ // 搜索功能
            let val = this.form.service.join("");
            if(this.form.address){
                val = val.concat(this.form.address)
            }
            this.$refs.mapSearch.handleSearch(val)
        },
        handleSave(form){ // 保存
            this.handleInfo({
                goodat: form.skilled, 
                certphoto: form.student_pic,
                serviceArea: form.service,
                address: form.address,
                briefInfo: form.brief,
                position: this.position
            }).then(res => {
                // this.$message({
                //     showClose: true,
                //     message: res.msg,
                //     type: res.type?"success":"error"
                // })
                this.$alert(res.msg, '提示', {
                    confirmButtonText: '确定'
                })
            })
        },
    },
    watch: {
        resource: {
            handler: "setForm",
            immediate: true,
            deep: true
        }
    },
}
</script>


<style scoped>
/* element-ui */
.el-form-item{
    border-bottom: 1px solid #F2F6FC;
}
/* 输入框 */
.form_input{
    width: 100%;
}

/* 我的资料盒子 */
.center_lensman_info_data_box{
    padding: 20px;
}

/* 头像 */
.avatar-uploader{
    display: inline-block;
    vertical-align: text-bottom;
    height: 52px;
}
.avatar-uploader>>>.el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader>>>.el-upload:hover {
    border-color: #409EFF;
}
.avatar_box{
    position: relative;
    width: 50px;
    height: 50px;
}
.avatar_box::before{
    display: block;
    content: "";
    padding-top: 100%;
}
.avatar_box>img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
}

/* 封面照 */
.center-lensman-info-pic-box{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    margin-bottom: 5px;
    width: 148px;
    height: 148px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
}
/* 删除 */
.center-lensman-info-pic-del{
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}
.del-hover{
    display: block;
}
.center-lensman-info-pic-del-btn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
/* 图片 */
.center-lensman-info-pic{
    width: 100%;
    height: 100%;
}
/* 上传 */
.center-lensman-info-upload{
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 5px;
}
/* 级联选择 */
/* .center-member-info-cascader{} */
/* 服务范围搜索框 */
.center-member-info-map-search,
.center-member-info-map-danger{
    margin-bottom: 10px;
}

/* 按钮区域 */
.center_lensman_info_data_btn_box{
    margin-top: 50px;
}
.center_lensman_info_data_btn_box>.el-button{
    width: 100px;
}
</style>
